// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import IMask from 'imask';

$(function() {
  // Date mask
  const $dateInputs = $('.js-date');
  const dateMaskOptions = {
    mask: 'd/m/Y',
    lazy: false,
    blocks: {
      d: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2,
      },
      m: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2,
      },
      Y: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 2200,
      }
    },
  };
  $dateInputs.each(function(_index, element){
    IMask(element, dateMaskOptions);
  });

  if (document.querySelectorAll("#subscription-payment-form").length) {
    import("../subscription_payment") // webpack will load this JS async
  } else if (document.querySelectorAll("#ebook-payment").length) {
    import("../ebook_payment") // webpack will load this JS async
  } else if (document.querySelectorAll(".pages-faq").length) {
    import("../faq") // webpack will load this JS async
  }

  // diapers page

  //Click to open and close the select menu
  $('.selected-option > p, .selected-option::after').on('click', function(e) {
    e.stopPropagation();
    const selectedOption = $(this).parent();
    const selectItems = selectedOption.next('.select-items');

    $('.select-items').not(selectItems).hide();
    $('.selected-option').not(selectedOption).removeClass('select-arrow-active');

    selectItems.slideToggle("slow");
    selectedOption.toggleClass('select-arrow-active');
  });

  //functions to call the updater info
  $('.select-items input[type="checkbox"]').on('change', function() {
      updateSelectedFilters($(this).closest('.diapers-filters__menu-brands'));
  });

  $('.select-items input[type="radio"]').on('change', function() {
      updateSelectedFilters($(this).closest('.diapers-filters__menu-size'));
  });

  // function to add submenus to remove filters, and return to standard state
  function updateSelectedFilters(filterMenu) {
    const selectedOptionDiv = filterMenu.find('.selected-option');
    const selectedFiltersDiv = selectedOptionDiv.find('.selected-filters');
    const itemsDiv = filterMenu.find('.select-items');

    selectedFiltersDiv.empty();

    const selectedCheckboxes = itemsDiv.find('input[type="checkbox"]:checked');
    const selectedRadio = itemsDiv.find('input[type="radio"]:checked');

    if (selectedCheckboxes.length > 0) {
        selectedCheckboxes.each(function() {
            const label = $(this).siblings('p').text();
            selectedFiltersDiv.append('<span class="filter-tag">' + label + ' <button class="remove-filter" data-name="marcas" data-value="' + $(this).val() + '">X</button></span>');
        });
    } else if (selectedRadio.length > 0) {
        const label = selectedRadio.siblings('p').text();
        selectedFiltersDiv.append('<span class="filter-tag">' + label + ' <button class="remove-filter" data-name="tamanho" data-value="' + selectedRadio.val() + '">X</button></span>');
    }

    if (selectedFiltersDiv.children().length > 0) {
        if (!selectedOptionDiv.data('original-text')) {
            selectedOptionDiv.data('original-text', selectedOptionDiv.find('p').text());
        }
        selectedOptionDiv.find('p').text('');
        selectedOptionDiv.addClass('option-has-filter');
        selectedFiltersDiv.show();
    } else {
        const originalText = selectedOptionDiv.data('original-text');
        if (originalText) {
            selectedOptionDiv.find('p').text(originalText);
            selectedOptionDiv.removeData('original-text');
        }
        selectedOptionDiv.removeClass('option-has-filter');
        selectedFiltersDiv.hide();
    }
  }

  // trigger the remove filter
  $(document).on('click', '.remove-filter', function(e) {
    e.stopPropagation();
    const filterName = $(this).data('name');
    const filterValue = $(this).data('value');
    $('input[name="' + filterName + '"][value="' + filterValue + '"]').prop('checked', false).trigger('change');
  });

  // Diapers FAQ toggle
  $(".diapers-faq__question-box-item-question").on('click', function() {
    const $answer = $(this).next(".diapers-faq__question-box-item-answer");
    $answer.slideToggle("slow");
    $(this).toggleClass("active");
  });

  // input change colors
  const form = document.querySelector(".form-component__container");

  if (form) {
    const inputs = form.querySelectorAll(".form-control");

    form.addEventListener("submit", function(event) {
      let isValid = true;

      inputs.forEach(input => {
        const errorMessage = document.createElement("span");
        errorMessage.className = "error-message";

        if (!input.value) {
          input.classList.add("error");
          errorMessage.innerText = "Este campo é obrigatório";
          input.parentElement.appendChild(errorMessage);
          isValid = false;
        } else {
          input.classList.remove("error");
          if (input.nextElementSibling && input.nextElementSibling.classList.contains("error-message")) {
            input.nextElementSibling.remove();
          }
        }
      });

      if (!isValid) {
        event.preventDefault();
      }
    });

    inputs.forEach(input => {
      input.addEventListener("focus", function() {
        this.classList.remove("error");
        if (this.nextElementSibling && this.nextElementSibling.classList.contains("error-message")) {
          this.nextElementSibling.remove();
        }
      });
    });
  }

  //Diapers FAQ component
  $(".faq-component__question-box-item-question").on('click', function() {
    const $answer = $(this).next(".faq-component__question-box-item-answer");
    $answer.slideToggle("slow");
    $(this).toggleClass("active");
  });
});
